/* .mainContainer{
    min-width: 100%;
}
.headerContainer h1 {
    color: #ffff;
    font-size: 28px;
    text-align: left;
}
@media (max-width:480px){
    .headerContainer h1{
        font-size: 18px;
    }
}
.adsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} */

.gradient {
  border-radius: 15px;
  padding: 2px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  /* margin-top: 15px; */
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
}
.adImage {
  background-color:black;
  /* width: fit-content; */
  max-height: 350px;
  height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* min-height: 450px; */
}

.adsWrapper {
  border-radius: 15px;
  background-color: #121410;
  width: fit-content;
  /* min-height: 450px; */
}
.adsWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.textDiv {
  text-align: left;
  padding: 16px 12px;
  color: #ffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
