.mainContainer {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  font-family: "Exo 2";
}

.headerContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
}

.headerText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.blurredPlot {
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(3px);
  z-index: 1;
  text-decoration: none;
  pointer-events: none;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #c2ea2b;
  background: #0a0c0a;
  border: 2px solid #c2ea2b;
  font-size: 14px;
  border-radius: 20rem;
  padding: 10px 40px;
  font-weight: bold;
  font-family: "Exo 2";
  transition: all 0.3s ease;
  cursor: pointer;
}

.button:hover {
  color: #050a02;
  background: #c2ea2b;
  transition: all 0.4s ease-in-out;
}
.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

@media (max-width: 1640px) {
  .headerText {
    font-size: 28px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    width: 100%;
  }
  .plotContainer {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .mainContainer {
    height: auto;
  }
}

@media (max-width: 480px) {
  .headerText {
    font-size: 24px;
  }
  
}

@media (max-width: 400px) {
  .headerText {
    font-size: 22px;
  }
}
