.mainContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  font-family: "Exo 2";
  min-height: 300px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  z-index: 1;
  height: 100%;
  /* background: url('../../public/images/circleBG.png'); */
}
.circleBG {
  position: absolute;
  /* top: -30px; */

  height: 100%;
  width: 100%;
}
.circleBG img {
  width: 100%;
  height: 100%;
}

.circleContainerWrapper {
  position: relative;
}
.blurredCard {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  filter: blur(3px);
  z-index: 1;
  text-decoration: none;
  pointer-events: none;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #c2ea2b;
  background: #0a0c0a;
  border: 2px solid #c2ea2b;
  font-size: 16px;
  border-radius: 20rem;
  padding: 10px 40px;
  font-weight: bold;
  font-family: "Exo 2";
  transition: all 0.3s ease;
  cursor: pointer;
}

.button:hover {
  color: #050a02;
  background: #c2ea2b;
  transition: all 0.4s ease-in-out;
}

.circleContainer {
  padding-left: 57px;
  height: 400px;
  display: flex;
  align-items: center;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 30px; */
}
.headerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerText {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  max-width: fit-content;
}

.headerContainer img {
  height: 21px;
  width: 21px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

@media (max-width: 1640px) {
  .headerText {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .mainContainer {
    min-height: 270px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    max-width: 100%;

    /* flex: 1 1 100%; */
  }

  .circleContainer {
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    min-height: 350px;
  }
  .subContainer {
    row-gap: 50px;
  }
  .circleContainer {
  }
}
@media (max-width: 900px) {
  .dropdownContainer {
    /* margin-left: 20px; */
  }
  .circleContainer {
    padding-left: 0px;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .headerContainer {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .headerText {
    text-align: left;
    font-size: 24px;
  }
  .circleBG img {
    border-radius: 13%;
  }
  .button {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .headerText {
    text-align: left;
    font-size: 22px;
  }
}
@media (max-width: 350px) {
  .headerContainer {
    gap: 0;
  }

  .headerContainer:nth-child(1) {
    margin-right: 20px;
  }
  .circleContainer {
    padding-left: 10px;
  }
}

.dropdownsWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
