.sliderContainer {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px !important;
}

.slider {
  position: relative;
  overflow: hidden !important;
}

.aiPowered {
  width: 100%;
}

.Mcard {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* min-height: 300px; */
  border-radius: 10px;
  min-height: 450px;
}

.ads{
min-height: auto;

}

@media (max-width: 768px) {
  .Mcard {
    /* min-height: 200px; */
  }
}
@media (max-width: 480px) {
  .sliderContainer {
    padding: 10px !important;
  }
}
