.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a0c0a;
  }
  
  .mainContainer {
    background: url('../public/images/bgDashboard.svg');

  
    background-position: left;
    background-repeat: no-repeat;
  
    /* background-color: black; */
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  
    /* background-position: center center;   */
  }

  