.mainContainer {
  color: white;
  font-family: "Exo 2";
  display: flex;
  justify-content: center;
  height: 100%;
}
.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.heading {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 100% */
  max-width: fit-content;
}

.headingContainer img {
  width: 21px;
  height: 21px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.cardContainerWrapper {
  position: relative;
  padding-top: 39px;
}
.blurredCard {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  filter: blur(3px);
  z-index: 1;
  text-decoration: none;
  pointer-events: none;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 30px;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #c2ea2b;
  background: #0a0c0a;
  border: 1px solid #c2ea2b;
  font-size: 16px;
  border-radius: 20rem;
  padding: 10px 40px;
  font-weight: bold;
  font-family: "Exo 2";
  transition: all 0.3s ease;
  cursor: pointer;
}

.button:hover {
  color: #050a02;
  background: #c2ea2b;
  transition: all 0.4s ease-in-out;
}

.card {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 11.25vw;
  height: 115px;
  justify-content: space-between;
  background-color: #1b1f19;
  border-radius: 20px;
  transition: transform 0.01s ease;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.card:hover {
  filter: brightness(1.1);
  transform: scale(1.02);
  cursor: pointer;
}

.cardContentContainer {
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  padding: 20px 12px 20px 20px;
}
.gradient {
  position: absolute;
  top: 0;
  /* left:50%; */
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
}

.gradient img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.cardDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  height: 100%;
}

.valueGreen {
  background: linear-gradient(to right, #caff00 0%, #f6ffd1 90%);
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.valueRed {
  background: linear-gradient(to right, #ff0000 0%, #ffcccc 90%);
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.valueGrey {
  background: grey;
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.collectionName {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.label {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.graph {
  display: flex;
  z-index: 1;
  /* width: 84px;
  height: 57px; */
}
.graph img {
  /* width: 100%;
  height: 100%; */
  max-height: 30.1px;
}

.infoIconWrapper {
  position: relative;
  display: flex;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.infoIconWrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1750px) {
  .subContainer {
    width: 100%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
  }

  .gradient {
    height: fit-content;
  }

  .card {
    position: relative;
    /* overflow: hidden; */
    display: flex;
    /* padding: 20px; */
    /* box-sizing: border-box; */
    width: 100%;
    /* max-width: 10.25vw; */
    height: 115px;
    justify-content: space-between;
    background-color: #1b1f19;
    border-radius: 20px;
  }

  .cardContentContainer {
    width: 100%;
    /* height: 100%; */
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    padding: 20px 12px 20px 20px;
    /* align-items: center; */

    /* flex-direction: column; */
  }

  .valueGreen {
    font-size: 22px;
  }

  .valueRed {
    font-size: 22px;
  }

  .valueGrey {
    font-size: 22px;
  }

  .collectionName {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .graph {
    display: flex;
    z-index: 1;
    width: 60px;
    /* width: 84px;
  height: 57px; */
  }
  .graph img {
    width: 100%;
    max-height: 31.1px;
  }
}

@media (max-width: 1640px) {
  .dropdownContainer .dropdown {
    display: flex;
    align-items: center;
    background-color: #121410;
    color: #fff;
    padding: 10px 27px;
    cursor: pointer;
    border-radius: 13px;
    position: relative;
    z-index: 3;
  }

  .headingContainer img {
    width: 21px;
    height: 21px;
  }

  .heading {
    color: #fff;

    /* font-family: 'Exo 2'; */
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
  }

  .cardContainerWrapper {
    padding-top: 30px;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 20px;
  }

  .card {
    height: 110px;
  }
}

@media (max-width: 1540px) {
  .cardContainerWrapper {
    padding-top: 30px;
  }
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  }
}

@media (max-width: 1440px) {
  .subContainer {
    max-width: 32.286vw;
  }
  .card {
    max-width: 10.25vw;
  }
  .graph {
    width: 45px;
  }
}

@media (max-width: 1415px) {
  .subContainer {
    /* max-width: 100%; */
    max-width: 670px;
  }

  .card {
    max-width: 170px;
    width: 170px;
  }

  .graph {
    max-height: 31.1px;
    width: 45px;
  }
}

@media (min-width: 2280px) {
  .card {
    max-width: 11.25vw;
  }

  .mainContainer {
    max-width: 34.896vw;
    width: 100%;
  }
  .subContainer {
    width: 100%;
  }
}

@media (min-width: 2460px) {
  .subContainer {
    width: 100%;
  }
  .card {
    max-width: 11.25vw;
  }
}

@media (min-width: 2720px) {
  .card {
    max-width: 11.25vw;
    /* max-width: 312px; */

    /* max-width: 275px; */
  }
}
@media (max-width: 570px) {
  .card {
    width: 32%;
  }
}

@media (max-width: 550px) {
  .card {
    height: 100px;
  }
  .cardContainer {
    row-gap: 10px;
    column-gap: 5px;
  }
  .cardContentContainer {
    padding: 10px;
  }
  .dropdownContainer {
    /* margin-left: 20px; */
  }
}

@media (max-width: 510px) {
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
    column-gap: 5px;
  }

  .card {
    width: 32%;
    max-width: unset;
  }
}

@media (max-width: 480px) {
  .label {
    font-size: 12px;
  }
  .heading {
    text-align: left;
    width: fit-content;
    font-size: 24px;
  }

  .valueGreen {
    font-size: 18px;
  }
  .valueRed {
    font-size: 18px;
  }
  .valueGrey {
    font-size: 18px;
  }
  .label {
    font-size: 14px;
  }

  .button {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .card {
    height: 100px;
  }
  .heading {
    text-align: left;
    width: fit-content;
    font-size: 22px;
  }
  /* .cardDetails {
    row-gap: 15px;
  } */
  .label {
    font-size: 12px;
  }
}
@media (max-width: 350px) {
  .cardContainer {
    justify-content: center;
  }
  .card {
    width: 48%;
  }
}
