.headerContainer {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoWrapper {
  cursor: pointer;

  width: 150px;
}

.logoWrapper img {
  width: 100%;
  height: 100%;
}

.btnWrapper {
  display: flex;
  gap: 12px;  /* Space between buttons */
  align-items: center;
}

.docsBtn {
  color: #050a02;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;
  font-size: 16px;      /* Slightly smaller than original 18px */
  border-radius: 20rem;
  padding: 8px 24px;    /* More compact than original 10px 40px */
  font-weight: bold;
  font-family: "Exo 2";
  transition: all 0.3s ease;
  cursor: pointer;
}

.docsBtn:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}

@media (max-width: 1640px) {
}
@media (max-width: 900px) {
  .logoWrapper {
    width: 110px;
  }
  .headerContainer {
    padding: 20px 10px;
  }
}
