.gaugeContainer {
  text-align: center;
  /* padding-bottom: 50px; */
  border-radius: 10px;
  color: #fff;
  position: relative;
  margin: 0 auto;
}

.gaugeWrapper {
  width: 620px;
  margin: 0 auto;
}

.gaugeLabel {
  margin-top: 13px;
  font-size: 40px;
  line-height: 106%;
}

.gaugeSubtext {
  margin-top: 7px;
  opacity: 0.5;
  font-size: 16px;
}

.marginDefault {
  margin-top: 10%;
  margin-bottom: 0%;
  margin-left: 11.3%;
  margin-right: 11.3%;
}
@media (max-width: 1850px) {
  .gaugeWrapper {
    /* width: 400px; */
    height: 100%;
    margin: 0 auto;
  }
  /* 
  .marginSmall {
    margin-top: 10%;
    margin-bottom: 0%;
    margin-left: 15%;
    margin-right: 15%;
  } */
}
@media (max-width: 480px) {
  .gaugeLabel {
    margin-top: 13px;
    font-size: 30px;
    line-height: 106%;
  }

  .gaugeSubtext {
    margin-top: 7px;
    opacity: 0.5;
    font-size: 14px;
  }
}
