.swiper {
  padding: 0px 0px 50px 0px;
}

.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #c2ea2b;
  background: rgba(194, 234, 43, 0);
  opacity: 1;
  margin: 0px 10px !important;
}

.swiper-pagination-bullet-active {
  background: #c2ea2b;
}
/* .swiper-wrapper {
  align-items: stretch;
} */

.swiper-slide {
  min-height: 450px;

}

.swiper-pagination {
  position: absolute;
  bottom: 0px !important;
  left: 0;
  right: 0;
}
