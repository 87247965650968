.container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* background-color: black; */
  height: fit-content;
  /* padding: 2rem; */
  border-radius: 0.5rem;
  column-gap: 30px;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.legendItem {
  display: flex;
  align-items: center;
}

.legendColor {
  width: 27px;
  height: 13px;
  margin-right: 0.5rem;
  border-radius: 9999px;
}

.legendLabel {
  font-size: 0.875rem;
  color: white;
}

.logoContainer {
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circleLogo {
  position: absolute;

  /* width: 88px !important; */
  left: 25%;
  top: 25%;
  /* height: 88px; */
  border-radius: 50%;
}

@media (max-width: 900px) {
  .container {
   justify-content: center;
  }
}
